export const benefits = [
  `Unique IB portal for real time rebate
      tracking, marketing link creation, sign-up 
      records, withdrawals and more.`,
  `Highly competitive rebates.`,
  `Hot desk access at our Ikoyi office
      for client meetings and demonstrations.
      `,
  `Free, tailored seminars hosted 
      in our prestigious offices for your 
      clients.`,
]
