import React from "react"
import ctl from "@netlify/classnames-template-literals"

const BenefitListItem = ({ item }) => {
  return (
    <li className={listItemStyle}>
      <span className={listStyle}></span>
      <p className={listParagraphStyle}>{item}</p>
    </li>
  )
}

const listItemStyle = ctl(`flex`)
const listParagraphStyle = ctl(`md-max:text-[15px] mt-0 text-left`)
const listStyle = ctl(
  `w-[13px] h-[13px] rounded-full bg-blue inline-block mr-[22px] md:mr-[30px] flex-shrink-0 items-start md:mt-[10px] mt-[6px]`
)

export default BenefitListItem
