import React from "react"
import Flex from "../../../utils/flex"
import { StaticImage } from "gatsby-plugin-image"
import { benefits } from "./benefits"
import ctl from "@netlify/classnames-template-literals"
import BenefitListItem from "./list-item"
import { Container } from "../../../utils"

const BenefitsOfPartnering = () => {
  return (
    <div className={backgroundRectangle}>
      <Container>
        <Flex
          secondItemClassName='md-max:mt-0'
          secondItem={
            <>
              <p className='md:hidden mt-0 max-w-[350px]'>
                We build our partnerships on transparency,
                longevity & trust with our partners being
                viewed as an extension of our team. Our partners
                range from individuals to national stockbrokers
                and we have a solution to fit every requirement.
              </p>
              <p className='mt-0 md-max:hidden'>
                We build our partnerships on transparency, longevity & trust with
                our partners being viewed as an extension of our team.
              </p>

              <p className="mt-[40px] md-max:hidden">
                Our partners range from individuals to national stockbrokers and
                we have a solution to fit every requirement
              </p>
            </>
          }
          className="md:pt-0"
        />
        <Flex
          firstItemClassName='md-max:relative md-max:w-full'
          firstItem={
            <>
              <div className={mobileRectangle} />
              <StaticImage
                src="../../../../assets/images/pages/partnerships/lady.webp"
                alt="Lady trading on her pc"
                className={imageStyle}
              />
            </>
          }
          secondItem={
            <>
              <h2 className={headingStyle}>Benefits of partnering</h2>
              <ol className='md-max:px-[20px] mt-10 md:mt-[28px] grid gap-y-[50px] md:gap-y-[30px]'>
                {benefits.map((benefit, index) => (
                  <BenefitListItem key={index} item={benefit} />
                ))}
              </ol>
            </>
          }
          secondItemClassName='md-max:mt-[50px]'
          reverseMobile
          className="md:pt-0"
        />
      </Container>
    </div>
  )
}

const imageStyle = ctl(`md:w-[454px] md:mt-[-430px] md-max:w-[298px] md-max:mx-auto`)
const headingStyle = ctl(`md:mt-[50px] font-extrabold`)
const backgroundRectangle = ctl(`
  md:border-t md:border-b md:border-brownish-grey md:bg-light-tangerine
  md:pt-[66px] md:pb-[96px] md:mt-[60px] md:mt-[92px]
`)
const mobileRectangle = ctl(
  `
    md:hidden
    w-[100vw] h-[289px]
    bottom-[131px] left-[-24px]
    border-t border-b border-brownish-grey bg-light-tangerine
    absolute
  `
)

export default BenefitsOfPartnering
