import * as React from "react"
import Layout from "../components/layout"
import { BecomeAnIntroductoryBroker, BecomeAPartner, BenefitsOfPartnering } from "../components/pages/partnerships"
import Container from "../components/utils/container"
import IntroductoryMobileImages from "../components/pages/partnerships/introductory-mobile-images"

const PartnershipsPage = () => (
  <Layout
    title="Become an Egmarkets partner"
    description='Earn commissions and take your business to the next level by partnering with Egmarkets; the premier partner for Introductory Brokers in Nigeria.'
  >
    <div className="relative overflow-visible">
      <BecomeAPartner />
    </div>
    <div className="relative">
      <BenefitsOfPartnering />
    </div>
    <div className="relative">
      <IntroductoryMobileImages/>
      <Container size='large'>
        <BecomeAnIntroductoryBroker />
      </Container>
    </div>
  </Layout>
)

export default PartnershipsPage
