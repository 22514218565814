import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const IntroductoryMobileImages = () => {
  return (
    <div className='grid grid-cols-2 gap-x-8 mt-[73px] md:hidden'>
      <StaticImage
        src="../../../assets/images/pages/partnerships/phone-mobile.webp"
        alt="Partner Chart Mobile"
      />
      <div className='absolute w-[677px] right-0 top-[-16px] -z-1'>
        <StaticImage
          src="../../../assets/images/pages/partnerships/trapezoid-mobile.svg"
          alt="Become a Partner"
        />
      </div>
      <StaticImage
        src="../../../assets/images/pages/partnerships/laptop-mobile.webp"
        alt="Partner Chart Laptop"
      />
    </div>
  )
}

export default IntroductoryMobileImages