import React from "react"
import Flex from "../../../utils/flex"
import { StaticImage } from "gatsby-plugin-image"
import ctl from "@netlify/classnames-template-literals"
import trapezoid from "../../../../assets/images/pages/partnerships/trapezoid.svg"
import { DesktopBreakpoint, MobileBreakpoint } from "../../../breakpoints"
import ButtonGroup from "../../../utils/button/group"

const buttons = [
  {
    label: `Get started`,
    url: `https://clients.eagleglobalmarkets.com/#/ib/signup`,
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
  {
    label: `Try Demo Trading`,
    url: "https://mt4.egmarkets.trade/",
    isExternal: true,
    className: 'md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm'
  },
];

const BecomeAnIntroductoryBroker = () => {
  return (
    <>
      <img src={trapezoid} className='md-max:hidden absolute right-[-138px] top-[90px]' alt="" />
      <Flex
        className='md-max:pt-[90px]'
        firstItemClassName='lg:!min-w-[480px] md:pl-[28px] md-max:w-full'
        secondItemClassName='md-max:hidden'
        firstItem={
          <>
            <h2 className={headingStyle}>
              Become a seasoned <br className='!block'/>
              Introductory broker
            </h2>
            <p className='md-max:mt-5 mb-[50px]'>
              Refer clients to trade on Egmarkets <MobileBreakpoint/> & <DesktopBreakpoint/>
              earn money every time they trade.
            </p>
            <ButtonGroup className='md-max:gap-y-[20px]' buttons={buttons} fullWidthOnMobile/>
          </>
        }
        secondItem={
          <StaticImage
            src="../../../../assets/images/pages/partnerships/become-an-introductory-broker.webp"
            alt="Become a Partner"
            className={imageStyle}
          />
        }
      />
    </>
  )
}

const headingStyle = ctl(`relative inline-block font-extrabold`)

const imageStyle = ctl(`md-max:hidden w-[570px]`)

export default BecomeAnIntroductoryBroker
