import React from "react"
import Flex from "../../../utils/flex"

import ctl from "@netlify/classnames-template-literals"
import { Button, Container } from "../../../utils"
import { DesktopBreakpoint, MobileBreakpoint } from "../../../breakpoints"
import Partnerships from "../../../animations/partnerships"

const BecomeAPartner = () => {
  return (
    <Container size="large" className='md-max:px-0'>
      <Flex
        alignTop
        isAtTopOfPage
        className='md-max:pt-[30px]'
        firstItemClassName="md:pl-[28px] md-max:px-6"
        firstItem={
          <>
            <h1 className="font-extrabold md:text-[40px] md:leading-[1.25]">Become a Partner</h1>
            <p className="md-max:mt-[30px]">
              Introduce clients to trade on our platforms <MobileBreakpoint /> and earn <DesktopBreakpoint />
              every time they trade. Egmarkets <MobileBreakpoint /> prides itself on being the <DesktopBreakpoint />
              premier partner for<MobileBreakpoint /> Introductory Brokers in Nigeria.
            </p>
            <Button
              label="Get Started"
              url="https://clients.eagleglobalmarkets.com/#/"
              isExternal
              className="w-[300px] md:w-[160px] md-max:mx-auto !mt-[30px] md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm"
            />
          </>
        }
        secondItemClassName="md-max:mt-[60px]"
        secondItem={
          <>
            <div className={imageStyle}>
              <Partnerships />
            </div>
          </>
        }
      />
    </Container>
  )
}

const imageStyle = ctl(`md:w-[384px] mx-auto`)
export default BecomeAPartner
